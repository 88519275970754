// this prehashing process is to ensure the user's password (which is used to unlock the private key)
// is never seen by the backend in plaintext

// the salt below is not for security, just to ensure the password hash doesn't appear in a rainbow table if it's captured in transit
// don't ever change this value; it will invalidate all passwords
const SALT = "ZQ$2Jj7tKtEyysDKBPfeiHU#2UJ*nmCaa#ynZ48vsZtdXu&57n#PQpF3it!r8F9A";

// implementation from:
//   https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
// but Supabase only accepts 72 chars for passwords, so we convert to base64 & truncate to the first 72 chars as per:
//   https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem

export async function passwordPreHash(message: string) {
  const msgUint8 = new TextEncoder().encode(message + SALT);
  const hashBuffer = await crypto.subtle.digest("SHA-512", msgUint8);
  const binString = String.fromCodePoint(...new Uint8Array(hashBuffer));
  return btoa(binString).substring(0, 72);
}
