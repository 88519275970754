import { MfaEnrolTokenInput, MfaEnrolTokenInputProps } from "@/components/authentication/MfaEnrolTokenInput";
import { MfaQrCode } from "@/components/authentication/MfaQrCode";
import { Button, Row, Space, Typography } from "antd";
const {
  Text
} = Typography;
export interface MfaEnrolConciseProps {
  handleVerifyMfa: MfaEnrolTokenInputProps["handleVerifyMfa"];
  /** URL to QR image. */
  qr?: string;
  /** Factor secret. */
  secret?: string;
  /** Called when "Show Tutorial" button clicked. */
  onShowTutorial: () => void;
}
export const MfaEnrolConcise = ({
  handleVerifyMfa,
  qr,
  secret,
  onShowTutorial
}: MfaEnrolConciseProps) => {
  return <Space direction="vertical" style={{
    width: "100%",
    padding: "0 2rem"
  }} data-sentry-element="Space" data-sentry-component="MfaEnrolConcise" data-sentry-source-file="MfaEnrolConcise.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="MfaEnrolConcise.tsx">Scan the following QR Code with your authentication app:</Text>
      <Row justify="center" data-sentry-element="Row" data-sentry-source-file="MfaEnrolConcise.tsx">
        <MfaQrCode qr={qr} secret={secret} data-sentry-element="MfaQrCode" data-sentry-source-file="MfaEnrolConcise.tsx" />
      </Row>
      <MfaEnrolTokenInput handleVerifyMfa={handleVerifyMfa} data-sentry-element="MfaEnrolTokenInput" data-sentry-source-file="MfaEnrolConcise.tsx" />
      <Button block onClick={onShowTutorial} data-sentry-element="Button" data-sentry-source-file="MfaEnrolConcise.tsx">
        Show Tutorial
      </Button>
    </Space>;
};