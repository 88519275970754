import { Button, notification, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { MfaEnrolConcise } from "@/components/authentication/MfaEnrolConcise";
import { MfaEnrolTour } from "@/components/authentication/MfaEnrolTour";
import styles from "@/components/authentication/SignUp.module.scss";
import supabase from "@/features/supabase";
import { useRouter } from "next/router";
const {
  Title
} = Typography;
type MfaEnrolType = {
  generateKeyPairs: () => Promise<void>;
};
const MfaEnrol = ({
  generateKeyPairs
}: MfaEnrolType) => {
  const router = useRouter();
  const [qr, setQr] = useState("");
  const [factorId, setFactorId] = useState("");
  const [factorSecret, setFactorSecret] = useState("");
  const [showTutorial, setShowTutorial] = useState(true);
  let init = false;
  useEffect(() => {
    // Ensure it runs only once.
    if (!init) {
      init = true;
      handleEnrolMfa();
    }
  }, []);
  const removeUnverifiedFactors = async (): Promise<string | undefined> => {
    const {
      data,
      error
    } = await supabase.auth.mfa.listFactors();
    if (error) {
      return error.message;
    }
    for (const {
      id: factorId
    } of data.all.filter(({
      status
    }) => status === "unverified")) {
      const {
        error
      } = await supabase.auth.mfa.unenroll({
        factorId
      });
      if (error) {
        return error.message;
      }
    }
  };
  const handleEnrolMfa = async () => {
    // Clear all unverified factors. Just ensures we have a clean slate when
    // trying to enrol someone. If it fails (which should be extremely
    // unlikely), bail out so as to avoid modifying MFA factors further.
    const factorErrorMessage = await removeUnverifiedFactors();
    if (typeof factorErrorMessage !== "undefined") {
      notification.error({
        message: factorErrorMessage
      });
      return;
    }
    const {
      data,
      error
    } = await supabase.auth.mfa.enroll({
      factorType: "totp",
      friendlyName: new Date().toISOString()
    });
    if (error) {
      notification.error({
        message: error.message
      });
      return;
    }
    setFactorId(data.id);
    setQr(data.totp.qr_code);
    setFactorSecret(data.totp.secret);
  };
  const handleVerifyMfa = async (verifyCode: string | undefined) => {
    if (!verifyCode) {
      throw new Error("Please enter your code.");
    }
    const challenge = await supabase.auth.mfa.challenge({
      factorId
    });
    if (challenge.error) {
      throw new Error(`${challenge.error.name}: ${challenge.error.message}. Please refresh the page and try again.`);
    }
    const verify = await supabase.auth.mfa.verify({
      factorId,
      challengeId: challenge.data.id,
      code: verifyCode.toString()
    });
    if (verify.error) {
      throw new Error(verify.error.message);
    }
    await generateKeyPairs();
    router.push("/");
  };
  const handleLogOut = async () => {
    await supabase.auth.signOut();
  };
  return <>
      <Title level={3} style={{
      marginBottom: 8
    }} className={styles.title} data-sentry-element="Title" data-sentry-source-file="MfaEnrol.tsx">
        Two factor authentication
      </Title>
      <Row data-sentry-element="Row" data-sentry-source-file="MfaEnrol.tsx">
        <Button type="link" onClick={handleLogOut} data-sentry-element="Button" data-sentry-source-file="MfaEnrol.tsx">
          Log Out
        </Button>
      </Row>
      {showTutorial ? <MfaEnrolTour handleVerifyMfa={handleVerifyMfa} onSkipTutorial={() => setShowTutorial(false)} qr={qr} secret={factorSecret} /> : <MfaEnrolConcise handleVerifyMfa={handleVerifyMfa} onShowTutorial={() => setShowTutorial(true)} qr={qr} secret={factorSecret} />}
    </>;
};
export default MfaEnrol;