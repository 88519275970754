import { Input, InputProps } from "antd";
export const MfaTokenInput = (props: InputProps) => {
  /**
   * InputNumber would be great, except that it doesn't allow for leading
   * zeroes. By using Input and validating, we can bypass this.
   */
  return <Input type="text" placeholder="Enter code" size="large" style={{
    width: "100%"
  }} autoComplete="one-time-code" required pattern="\d{6}" maxLength={6} count={{
    show: true,
    max: 6
  }} {...props} data-sentry-element="Input" data-sentry-component="MfaTokenInput" data-sentry-source-file="MfaTokenInput.tsx" />;
};