import { Turnstile, TurnstileInstance, TurnstileProps } from "@marsidev/react-turnstile";
import { useEffect, useRef, useState } from "react";
export type CaptchaState = undefined | {
  current: TurnstileInstance;
};
export type CaptchaProps = {
  onSuccess: TurnstileProps["onSuccess"];
  // TODO idk 😓
  setCaptchaRef: any;
  // setCaptchaRef: (arg0: MutableRefObject<undefined | TurnstileInstance>) => CaptchaState;
};
const siteKey = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY || "";
const invisible = {
  height: 0
};
const visible = {
  margin: "auto",
  marginBottom: 14
};
const options = {
  appearance: "interaction-only"
} as TurnstileProps["options"];
export const Captcha = ({
  onSuccess,
  setCaptchaRef
}: CaptchaProps) => {
  const ref = useRef<TurnstileInstance>();
  useEffect(() => {
    setCaptchaRef(ref);
  }, [ref]);

  // by default it has a height, even when invisible
  const [style, setStyle] = useState<object>(invisible);
  const onBeforeInteractive = () => setStyle(visible);
  const onError = () => console.error("Captcha onError fired");
  const onExpire = () => console.info("Captcha onExpire fired");
  const onUnsupported = () => console.warn("Captcha onUnsupported fired");
  return <Turnstile {...{
    siteKey,
    options,
    onSuccess,
    ref,
    style,
    onBeforeInteractive,
    onError,
    onExpire,
    onUnsupported
  }} data-sentry-element="Turnstile" data-sentry-component="Captcha" data-sentry-source-file="Captcha.tsx" />;
};