import { LoadingOutlined } from "@ant-design/icons";
import { Image, Row, Space, Typography } from "antd";
import styles from "./MfaQrCode.module.scss";
const {
  Text
} = Typography;
export interface MfaQrCodeProps {
  /** URL to QR image. */
  qr?: string;
  /** Factor secret. */
  secret?: string;
}

/**
 * QR code for setting up MFA.
 * @returns ReactNode
 */
export const MfaQrCode = ({
  qr,
  secret
}: MfaQrCodeProps) => {
  if (!qr) {
    return <div className={styles.container}>
        <LoadingOutlined />
      </div>;
  }
  return <Space direction="vertical" data-sentry-element="Space" data-sentry-component="MfaQrCode" data-sentry-source-file="MfaQrCode.tsx">
      <Row justify="center" data-sentry-element="Row" data-sentry-source-file="MfaQrCode.tsx">
        <Image src={qr} alt="QR code for authenticator" preview={false} className={styles.container} data-sentry-element="Image" data-sentry-source-file="MfaQrCode.tsx" />
      </Row>
      <Text data-sentry-element="Text" data-sentry-source-file="MfaQrCode.tsx">If you can&apos;t scan the QR Code, copy this seed into your token generator:</Text>
      <Row justify="center" data-sentry-element="Row" data-sentry-source-file="MfaQrCode.tsx">
        <Text code data-sentry-element="Text" data-sentry-source-file="MfaQrCode.tsx">{secret}</Text>
      </Row>
    </Space>;
};