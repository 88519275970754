import { MfaEnrolTokenInput, MfaEnrolTokenInputProps } from "@/components/authentication/MfaEnrolTokenInput";
import { MfaQrCode } from "@/components/authentication/MfaQrCode";
import { Alert, Button, Col, Divider, Image, Row, Space, StepProps, Typography } from "antd";
import Link from "next/link";
import { ReactNode, useState } from "react";
const {
  Text,
  Title
} = Typography;
export interface MfaEnrolTourProps {
  handleVerifyMfa: MfaEnrolTokenInputProps["handleVerifyMfa"];
  /** URL to QR image. */
  qr?: string;
  /** Factor secret. */
  secret?: string;
  /** Called when the "Skip Tutorial" button is clicked. */
  onSkipTutorial: () => void;
}
interface MfaEnrolTourStep extends StepProps {
  content: ReactNode;
}
export const MfaEnrolTour = ({
  handleVerifyMfa,
  qr,
  secret,
  onSkipTutorial
}: MfaEnrolTourProps) => {
  const [index, setIndex] = useState(0);
  const items: MfaEnrolTourStep[] = [
  // Step 1: Download
  {
    title: "",
    content: <Space direction="vertical">
          <Text>
            Download an authentication app such as &quot;
            <Link target="_blank" href="https://www.microsoft.com/en-au/security/mobile-authenticator-app">
              Microsoft Authenticator
            </Link>
            &quot; or &quot;
            <Link target="_blank" href="https://2fas.com/">
              2FAS Auth
            </Link>
            &quot; onto your phone, if you do not have one installed already.
          </Text>
          <Image alt="Mobile phone homescreen icons for both 'Authenticator' and '2FAS Auth' apps." src="/img/tours/mfa-enrol/step-1-apps.png" preview={false} />
          <Text type="secondary">
            NOTE: The steps for these two are similar. For this demonstration, the following steps will use Microsoft
            Authenticator.
          </Text>
        </Space>
  },
  // Step 2: Open app
  {
    title: "",
    content: <Space direction="vertical">
          <Text>Open the app on your phone and click the &quot;+&quot; in the top right hand corner.</Text>
          <Image alt="Microsoft Authenticator homescreen, with indication to click the 'plus' icon to add a new code." src="/img/tours/mfa-enrol/step-2-add.png" preview={false} />
        </Space>
  },
  // Step 3: Start process
  {
    title: "",
    content: <Space direction="vertical">
          <Text>Click &quot;Work or school account&quot; to enter the Scan QR code page.</Text>
          <Image alt="'What kind of account are you adding?' section. Indication to click 'Work or school account'." src="/img/tours/mfa-enrol/step-3-account.png" preview={false} />
        </Space>
  },
  // Step 4: Scan QR
  {
    title: "",
    content: <Space direction="vertical">
          <Text>Scan the following QR Code:</Text>
          <MfaQrCode qr={qr} secret={secret} />
          <Text>Click &quot;Allow&quot; to bind your two factor authentication to the device.</Text>
        </Space>
  },
  // Step 5: Code
  {
    title: "",
    content: <Space direction="vertical">
          <Text>
            You should be able to see a new account &quot;{window.location.host}&quot; added, with a 6-digit code
            displayed.
          </Text>
          <Image alt="Indication to use the six-number code on the entry you've just added." src="/img/tours/mfa-enrol/step-4-list.png" preview={false} />
          <Alert type="info" message={<>
                Each code is only valid for a limited period of time and you can see the remaining validity period in
                the circular timer next to the code. If the validity period has expired, a new code will be generated
                automatically and the timer will start again.
              </>} />
          <Divider />
          <MfaEnrolTokenInput handleVerifyMfa={handleVerifyMfa} />
        </Space>
  }];
  const {
    content
  } = items[index];
  return <Row gutter={[0, 8]} style={{
    marginTop: "2rem",
    width: "100%"
  }} data-sentry-element="Row" data-sentry-component="MfaEnrolTour" data-sentry-source-file="MfaEnrolTour.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="MfaEnrolTour.tsx">
        <Title level={3} style={{
        marginTop: "0"
      }} data-sentry-element="Title" data-sentry-source-file="MfaEnrolTour.tsx">
          Step {index + 1}
        </Title>
      </Col>
      <Col span={24} style={{
      marginBottom: ".5rem"
    }} data-sentry-element="Col" data-sentry-source-file="MfaEnrolTour.tsx">
        {content}
      </Col>
      {index < items.length - 1 && <Row justify="space-between" style={{
      width: "100%"
    }}>
          <Col>
            <Button onClick={onSkipTutorial}>Skip Tutorial</Button>
          </Col>
          <Col>
            <Space>
              <Button disabled={index === 0} onClick={() => setIndex(index - 1)}>
                Previous
              </Button>
              <Button onClick={() => setIndex(index + 1)} type="primary">
                Next
              </Button>
            </Space>
          </Col>
        </Row>}
      {index === items.length - 1 && <Button block onClick={() => setIndex(index - 1)}>
          Go Back to QR Code
        </Button>}
    </Row>;
};