import { MfaTokenInput } from "@/components/authentication/MfaTokenInput";
import styles from "@/components/authentication/SignUp.module.scss";
import { Button, Form, Space } from "antd";
import { useState } from "react";
export interface MfaEnrolTokenInputProps {
  handleVerifyMfa: (code: string) => Promise<void>;
}
export const MfaEnrolTokenInput = ({
  handleVerifyMfa
}: MfaEnrolTokenInputProps) => {
  const [error, setError] = useState<string>();
  const [verifyCode, setVerifyCode] = useState<string>();
  const onFinish = async (code: string) => {
    try {
      await handleVerifyMfa(code);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
        return;
      }
      setError("Unknown error occurred");
    }
  };
  return <Form layout="vertical" onFinish={({
    verifyCode
  }) => onFinish(verifyCode)} className={styles.form} data-sentry-element="Form" data-sentry-component="MfaEnrolTokenInput" data-sentry-source-file="MfaEnrolTokenInput.tsx">
      <Form.Item label="Enter the 6-digit code generated by the app" name="verifyCode" validateStatus={error && "error"} help={error} data-sentry-element="unknown" data-sentry-source-file="MfaEnrolTokenInput.tsx">
        <MfaTokenInput value={verifyCode} onChange={e => setVerifyCode(e.target.value || "")} data-sentry-element="MfaTokenInput" data-sentry-source-file="MfaEnrolTokenInput.tsx" />
      </Form.Item>
      <Space direction="vertical" style={{
      width: "100%"
    }} data-sentry-element="Space" data-sentry-source-file="MfaEnrolTokenInput.tsx">
        <Button type="primary" block htmlType="submit" data-sentry-element="Button" data-sentry-source-file="MfaEnrolTokenInput.tsx">
          Verify
        </Button>
      </Space>
    </Form>;
};