import { XdiAttribution } from "@/components/layouts/XdiAttribution";
import { getAestheticsConfig, getTheme } from "@/features/branding";
import { Image } from "antd";
import Link from "next/link";
import { ReactNode } from "react";
import styles from "./AuthLayoutBrandingWrapper.module.scss";
interface AuthLayoutBrandingWrapperProps {
  children: ReactNode;
}

/**
 * Wraps the child in vertically-stacked branding.
 */
export const AuthLayoutBrandingWrapper = (props: AuthLayoutBrandingWrapperProps) => {
  const {
    meta
  } = getTheme();
  const {
    attribution
  } = getAestheticsConfig();
  const logoUrl = meta.logoUrl || null;
  return <>
      <Link href="/" data-sentry-element="Link" data-sentry-source-file="AuthLayoutBrandingWrapper.tsx">{logoUrl && <Image src={logoUrl} alt="Logo" preview={false} className={styles.logo} />}</Link>
      {props.children}
      {attribution === "poweredByXdi" && <div className={styles.attributionContainer}>
          <XdiAttribution mode="vertical" />
        </div>}
    </>;
};