import { Login } from "@/components/authentication/Login";
import { NextPageWithLayout } from "@/pages/_app";
import { store } from "@/store";
import { supabaseApi } from "@/store/services/supabase";
import { useKeys } from "@/utils/hooks/useKeys";
import { ReactElement, useEffect } from "react";
const LoginPage: NextPageWithLayout = () => {
  const [keys] = useKeys();
  useEffect(() => {
    keys.reset();
    sessionStorage.clear();
    invalidateSupabaseCache();
  }, []);
  const invalidateSupabaseCache = () => {
    store.dispatch(supabaseApi.util.invalidateTags([{
      type: "User"
    }, {
      type: "Domain"
    }, {
      type: "Group"
    }, {
      type: "Slug"
    }, {
      type: "Org"
    }, {
      type: "Portfolio"
    }, {
      type: "PortfolioAssets"
    }, {
      type: "Keys"
    }, {
      type: "User",
      id: "CURRENT"
    }]));
  };
  return <Login data-sentry-element="Login" data-sentry-component="LoginPage" data-sentry-source-file="login.tsx" />;
};

// Login component provides AuthLayout since it configures it.
LoginPage.getLayout = (page: ReactElement) => {
  return page;
};
export default LoginPage;